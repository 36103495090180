import moment from "moment"

export const backupsAdapter = (backups) => {
    
    let formattedBackups = []
    let unit = 'GB';
    for (let backup of backups.data.items) {
        const sizeInBytes = backup.Size || 0; // Valor en bytes o 0 si no está definido
        let size;
        // Validación en GB
        if (sizeInBytes > 0) {
            size = Number((sizeInBytes / 1073741824).toFixed(2)); // Calcula en GB
            console.log("Size calculated in GB:", size);
        }
        // Si el tamaño en GB es 0, calcula en KB
        if (size === 0) {
            size = Number((sizeInBytes / 1024).toFixed(2)); // Calcula en KB
            console.log("Size calculated in KB:", size);
            unit = 'KB'
        }
        formattedBackups.push({
            id: backup.BackupId || "",
            size: size || 0,
            database: backup.Database || "",
            start_date: backup.StartDate || "",
            status: backup.Status || "",
            unitStorage: unit
        })
    }

    return [backups.item_count, formattedBackups]
}

export const lastBackupsAdapter = (backups) => {
    
    let formattedLastBackups = backups.data.items.sort((a, b) => b.StartDate > a.StartDate)[0]
    formattedLastBackups.Checksum = backups.data.last_backup.checksums || ""
    return formattedLastBackups
}